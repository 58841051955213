import css from '../css/login.module.css'
import { Link } from 'react-router-dom'
import { FcGoogle as Google } from 'react-icons/fc' 
import { BsEyeFill as OpenEye } from 'react-icons/bs' 
import { BsEyeSlashFill as CloseEye } from 'react-icons/bs'
import { useState, useContext, useEffect } from 'react'
import { HomeContext } from "../home";
import Logo from '../../component/logo';
import Api from '../../api'

var valid_email =  /^(([^<>()[\],;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const SecondaryLogin = () => {
    const [changeType, setChangeType] = useState("password")
    const [showEye,setShowEye] =useState(true);
    const [email,set_email] = useState('');
    const [password,set_password] = useState('');
    const [businessId,set_businessId] = useState('');
    const [enableBtn ,setEnableBtn] = useState(true)
    const {setShowLoading} = useContext(HomeContext);
    const [disabled,set_disabled] = useState(true);


    const handleEmail = (e)=>{
        const {target:{value}} = e;
        set_email(value);
        if(email.match(valid_email)){
            set_disabled(false);
        }
        else{
            set_disabled(true);
        }
    }
    
    const handleLogin = (e) =>{
        e.preventDefault();
        if(!Api.check_network() && !disabled) return;
        setShowLoading(true);
        const url=`account/login`;
        const body={
            email,
            password,
            businessId
        }
        Api.post({url,body},false)
        .then(data =>{
            console.log(data,'data');
            alert(data.message);
            setShowLoading(false);
        })
        .catch(err =>{
            console.log(err,'error');
            setShowLoading(false);
            alert(err.message);
        })
    }

    const changeInpType = ()=>{
        if(changeType === "password"){
          setChangeType('text')
          setShowEye(false)
        }
        else{
          setChangeType("password")
          setShowEye(true)
        }
    }
    const { setColor, setColor1 } = useContext(HomeContext);
        useEffect(()=>{
        setColor('#828282');
        setColor1('#002087')
    })

    useEffect(()=>{
        if(email.length > 0 && password.length > 0 && businessId.length > 0){
          setEnableBtn(false)
        }
        else{
          setEnableBtn(true)
        }
      },[email, password, businessId])

  return (
    <form className={css.form} onSubmit={handleLogin}>
        <Logo/>
        <div className={css.form1}>
            <span className={css.head1}>Welcome Back</span>
            <span className={css.head2}>Don't have an account? <Link to='/register'>sign up</Link></span>
        </div>
        <div className={css.form2}>
            <div>
                <input value={businessId} onChange={(e)=>set_businessId(e.target.value)} className={css.forminp} type="text" placeholder='Organisation ID' name="" id="" />
                <input value={email} onChange={(e)=>handleEmail(e)} className={css.forminp} type="email" placeholder='User email' name="" id="" />
                <div className={css.reform}>
                    <div>
                        <input value={password} onChange={(e)=>set_password(e.target.value)} className={css.forminp} type={changeType} placeholder='Password' name="" id="" />
                        {showEye === true? <OpenEye className={css.img3} onClick={()=> changeInpType()} />:<CloseEye className={css.img3} onClick={()=> changeInpType()} />}
                    </div>
                    <Link to='/forgot-password'>Forgot Password?</Link>
                </div>
            </div>
            <div className={css.form3}>
                <button disabled={enableBtn} style={!enableBtn?{cursor:'pointer'}:{cursor:'not-allowed'}}>Login</button>
                <span className={css.or}>or</span>
                <Link><Google/>Continue with google</Link>
            </div>
        </div>
    </form>
  )
}

export default SecondaryLogin