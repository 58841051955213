import css from '../css/email.module.css'
import { Link } from 'react-router-dom'
import { useEffect, useContext, useState } from 'react';
import { HomeContext } from "../home";
import Logo from '../../component/logo'
import { AppContext } from '../../App';
import Api from '../../api';

const Signup = () => {
  const { setColor, setColor1 } = useContext(HomeContext);
  const {userEmail,id} = useContext(AppContext);
  const {setShowLoading} = useContext(HomeContext)
  const [disable,setDisable] = useState(false);
  const hideEmail = (email)=>{
    let arr = email.split('@');
    let start = arr[0];
    let end = arr[1].split('.')[0];
    let ext = arr[1].split('.')[1]
    return `${start.charAt(0)}${start.charAt(1)}****@${end.charAt(0)}***.${ext}`;
  }
  const resendVerification = ()=>{
      if(!Api.check_network()) return;
      setShowLoading(true);
      setDisable(true);
      const url = `account/resend-verify-email`;
      const body = {
        id:id,
        email:userEmail
      }
      Api.post({url,body},false)
      .then(data =>{
        console.log(data,'data');
        if(data.isSuccessful){
          alert(data.message);

        }
        else{
          alert(data.message);

        }
        setDisable(false);
        setShowLoading(false);

      })
      .catch(err =>{
        console.log(err,'error');
        setDisable(false);
        setShowLoading(false);
      })

  }
  useEffect(()=>{
    setColor1('#828282');
    setColor('#002087')

  },[])
  return (
    <div className={css.veri}>
      <Logo/>
      <div className={css.veri1}>
        <span className={css.verisp1}>Verify your Email</span>
        <span className={css.verisp2}>
          We have sent a verification email to {hideEmail(userEmail)}
        </span>
        <span>
          Click <Link to="/login">here</Link> to login 
        </span>
        
      </div>
      <div className={css.veri1}>
        <span className={css.verisp2}>Didn’t receive the email? Check spam or promotion folder or</span>
        <button disabled={disable} onClick={resendVerification}>Resend Mail</button>
      </div>          
    </div>
  )
}

export default Signup