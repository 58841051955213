import css from '../views/css/accountsidebar.module.css'
import pic from '../views/images/logo.png'
import {Link} from 'react-router-dom';

const AccountSideBar = ( props ) => {
  const color = props.color
  const color1 = props.color1
  const style = {color:color}
  const style1 = {color:color1}
  
  return (
    <div className={css.mainSideBar}>
      <div className={css.top}>
          <img src={pic} alt="" />
          <span className={css.subcontent}>Manager</span>
      </div>
      <div className={css.middle}>
          <Link to="/"  style={style}  className={css.mid1}>Sign Up</Link>
          <Link to='/login'  style={style1} className={css.mid2}>Login</Link >
      </div>
      <div className={css.bottom}>
          <span className={css.bot1}>Better Workflow</span>
          <span className={css.bot2}>Corporate manager is  key for ensuring the success and sustainability of an organization.</span>
      </div>
    </div>
  )
}

export default AccountSideBar