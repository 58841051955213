import css from '../css/email.module.css'
import { Link, useParams } from 'react-router-dom';
import { useEffect, useContext, useState } from 'react';
import { HomeContext } from "../home";
import Logo from '../../images/logo.png';
import HTMLReactParser from 'html-react-parser';
import Api from '../../api';
import styled from 'styled-components';

const CheckEmail = () => {
  const { setColor, setColor1 } = useContext(HomeContext);
  const [response,set_response] = useState({issuccessful:false,message:''});
  const {setShowLoading} = useContext(HomeContext)
  const {encrypt:id} = useParams();


  const handleVerifyEmail = ()=>{
      if(!Api.check_network()) return false;
      else{
        //console.log(id,'id');
        const url = `account/verify-email/${id}`;
        const body = {id}
        Api.post({url,body},false)
        .then(data =>{
          console.log(data,'data');
          if(data.isSuccessful){
               set_response(data);
          }
          else{
            set_response(data);
          }
          setShowLoading(false);

        })
        .catch(err =>{
          console.log(err,'error');
          set_response(err);
          setShowLoading(false);

        })
      }
  }
  
  useEffect(()=>{
    setColor('#828282');
    setColor1('#002087');
    setShowLoading(true);
    if(id){
      handleVerifyEmail();
    }
  },[])
  return (
          
    <Wrapper {...{...response,issuccessful:response.issuccessful?'1':''}} >
      <div>
          <img alt="C-Manager Logo" src={Logo} width="100" height="auto" />
          <span>MANAGER</span>
      </div>
      <div>
        {response && !response.message ? response.message : HTMLReactParser(response.message)}
      </div>
    </Wrapper>    
  )
}

export default CheckEmail;

const Container = (props) =>{
  const {children }= props;
  return (<section {...props}>{children}</section>)

}

const Wrapper = styled(Container)`
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  & > div:first-of-type{
    display: flex;
    justify-content: center;
    align-items: center;
    padding:30px 0 10px 0;
    gap:0px;

    & > img{
        width:80px;
        height: auto;
        object-fit: contain;
        object-position: right center;
    }
    & > span{
      font-weight: bold;
      position: relative;
      left:-20px;
    }
  }
  & > div:nth-of-type(2){
            
  
    width:max(40%,360px);
    background-color: #fff;
    box-shadow: 2px 1px 10px 2px #eee;
    border-radius: 10px;
    padding: 10px 20px 30px 20px;
    & > *{
      margin:0;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
    & > h1{
      display: flex;
      gap:10px;
      align-items: flex-start;
      justify-content: center;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      color: ${props => props.issuccessful?'green':'#ff5050'};
      margin-bottom: 20px;
    }
    & > p{
        font-size: 18px;
        text-align: justify;
    }
  }
  
`