import { useCookies } from "react-cookie";
const Cookies = (command, name, value) =>{
    const [cookies, setCookie, removeCookie] = useCookies(['token', 'user']);

    switch (command) {
            case 'set':
                setCookie(name, value);
            break;
        case 'remove':
            removeCookie(name);
            break;
        default:
            return cookies;
            break;
    }
}
class restApi{
    //Set domain name here
    static domain = process.env.NODE_ENV == 'production'?`https://api.c-manager.xyz`:`http://localhost:5000`;
    // static domain = `http://localhost:5000`;
    // static domain = `https://api.c-manager.xyz`;

    static domainState = false;

    static checkNetwork(){
        if(!navigator.onLine){alert("You are offline"); return false}
        return true;
    }

    static PostApi(url, requestObject, shouldAddAuthorization=false, isDomainUsed=restApi.domainState){

        if(!isDomainUsed){
            url = restApi.domain + url;
        }
       // console.log(requestObject)
        return new Promise((resolve,reject)=>{
            fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                headers: shouldAddAuthorization ? {
                    // 'Authorization': 'Bearer ' + localStorage.getItem('cmanager_tkn1234'),
                    'Authorization': 'Bearer ' + Cookies().token,
                    'Content-Type': 'application/json'
                } : {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestObject)
            })
            .then(Response=>Response.json())
            .then(result=>{
                //console.log(result)
                if(!result.isSuccessful && result.status===403){
                    // localStorage.removeItem('cmanager_tkn1234');
                    Cookies("token");
                    alert("Authentication failed. Please re-login.")
                    window.location.href = "/primary-login";
                }
                else resolve(result)
            })
            .catch(error=>{
                console.log(error,'this error')
                resolve({
                    isSuccessful: false,
                    message:"Connectivity error. Please check internet network and try again"
                });
            })
        }) 
    }
    static getApi(url,authorizationString=false,isDomainUsed = restApi.domainState){
        if(!isDomainUsed){
            url = restApi.domain + url;
        }

        return new Promise((resolve,reject)=>{
            fetch(url,{
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                headers: authorizationString && {
                    // 'Authorization': 'Bearer ' + localStorage.getItem('cmanager_tkn1234'),
                    'Authorization': 'Bearer ' + Cookies().token,
                }
            })
            .then(Response=>Response.json())
            .then(result=>{
                //console.log(result)
                if(!result.isSuccessful && result.status===403){
                    // localStorage.removeItem('eduplus_tkn');
                    Cookies("token");
                    alert("Authentication failed. Please re-login.")
                    window.location.href = "/account/login";
                }
                else resolve(result)
            })
            .catch(error=>{
                console.log(error,'this error')
                alert("Connectivity error. Please check internet network and try again")
                resolve({
                    isSuccessful: false,
                    message:"Connectivity error. Please check internet network and try again"
                });
            })
        })
    }
}
export default restApi;
