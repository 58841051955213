import css from './css/non-found.module.css'
import Pic from './images/404-error.jpg'
import { useEffect } from 'react';
//const BASE_URL = 'http://localhost:3000';
const BASE_URL = 'http://c-manager.xyz';

export default function NotFound() {
  useEffect(()=>{
    window.location.href = `${BASE_URL}/not-found`;
  },[])
  return (
    <div className={css.non}>
      <img src={Pic} alt='404'/>
      <span>Oops! Page not Found!</span>
    </div>
  );
}