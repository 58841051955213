import { useCookies } from "react-cookie";

const BASE_URL = process.env.NODE_ENV == 'production'?`https://api.c-manager.xyz`:`http://localhost:5000`;

// const BASE_URL = `https://api.c-manager.xyz`;


class Api_Request{

    isDomainUsed = false;
    token = null;
    
    setToken = (cookies)=>{
        this.token = cookies.token;
    }
    check_network = ()=>{
        return window.navigator.onLine;
    }

    get_url = (url) =>{
        return this.isDomainUsed ? url : `${BASE_URL}/${url}`;
    }
    
    get = (url,shouldAddAuthorization=true)=>{
        console.log(this.token)
        return new Promise((resolve,reject)=>{
            fetch(`${this.get_url(url)}`,{
                method:'GET',
                headers:shouldAddAuthorization?{
                    "Authorization":"Bearer "+ this.token,
                    "Content-Type":"application/json"
                }:{"Content-Type":"application/json"},
            }).then(res =>{
                if(res.ok && res.status > 199 && res.status < 300){
                    return(res.json());
                }
                reject(res);
            })
            .then(data => {
                if(data.token){
                    this.token = data.token;
                }
                resolve(data);
            })
            .catch(err => {
                reject(err);
            })
        })
    }

    post = ({url,body={}},shouldAddAuthorization=true)=>{
        return new Promise((resolve, reject) => {
            console.log(this.token,process.env.NODE_ENV,'token')
            fetch(`${this.get_url(url)}`,{
                method:"POST",
                headers:shouldAddAuthorization?{
                    "Authorization":"Bearer "+ this.token,
                    "Content-Type":"application/json"
                }:{"Content-Type":"application/json"},
                body:JSON.stringify(body)
            })
            .then(res =>{
                if(res.ok && res.status > 199 && res.status < 300){
                    return(res.json());
                }
                reject(res);
            })
            .then(data => {
                if(data.token){
                    this.token = data.token;
                }
                resolve(data);
            })
            .catch(err => {
                reject(err);
            })
        }) 
    }

    form_post = ({url,body},shouldAddAuthorization=true)=>{
        return new Promise((resolve,reject)=>{
            let xhhtp = new XMLHttpRequest();
            xhhtp.onreadystatechange = function(){   
                //console.log(`state: ${this.readyState} | status: ${this.status}`)               
                if (this.readyState == 4 && this.status <= 299){
                    let res = JSON.parse(xhhtp.responseText)
                    console.log(res)  
                    resolve(res)                          
                }
                else if(this.status >= 300){
                    reject({
                        isSuccessful: false
                    })
                }
            }
            xhhtp.open("POST", this.get_url(url), true); 
            if(shouldAddAuthorization){
                xhhtp.setRequestHeader("Authorization", "Bearer " + this.token);
            }
            xhhtp.send(body);
        })
    }

}

const Api = new Api_Request();
export default Api;