import css from '../css/mainsignup.module.css'
import { Link } from "react-router-dom"
import { FcGoogle as Google } from 'react-icons/fc' 
import { BsEyeFill as OpenEye } from 'react-icons/bs' 
import { BsEyeSlashFill as CloseEye } from 'react-icons/bs'
import { useEffect, useState, useContext } from 'react'
import { HomeContext } from "../home";
import Logo from '../../component/logo';
import Api from '../../api';
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../../App'
import { useCookies } from 'react-cookie'

const MainSignUp = () => {
  const [password ,setPassword] = useState("")
  const [email ,setEmail] = useState("")
  const [enableBtn ,setEnableBtn] = useState(true)
  const [addCheck ,setAddCheck] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState("")
  const [userName, setUserName] = useState("")
  const [result, setResult] = useState("")
  const [result1, setResult1] = useState("")
  const [changeType, setChangeType] = useState("password")
  const [changeType1, setChangeType1] = useState("password")
  const [showEye,setShowEye] =useState(true)
  const [showEye1,setShowEye1] =useState(true);
  const navigate = useNavigate();
  const {setUserEmail,setId} = useContext(AppContext);
  const [cookies] = useCookies(['user']);
  

  useEffect(()=>{
    var validRegex =  /^(([^<>()[\],;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(email.match(validRegex)){
      setResult1("")
    }
    else{
      setResult1('invalid email')
    }
    if(password === confirmPassword){
      setResult("")
    }
    else{
      setResult("password does not match")
    }
    if(userName.length > 0  && email.length > 0 && password.length > 0 && confirmPassword.length > 0 && addCheck === true ){
        setEnableBtn(false);
      }
    else{
      setEnableBtn(true)
    }
  },[userName,email,password,confirmPassword,addCheck])

  const changeInpType = ()=>{
    if(changeType === "password"){
      setChangeType('text')
      setShowEye(false)
    }
    else{
      setChangeType("password")
      setShowEye(true)
    }
  }
  const changeInpType1 = ()=>{
    if(changeType1 === "password"){
      setChangeType1('text')
      setShowEye1(false)
    }
    else{
      setChangeType1("password")
      setShowEye1(true)
    }
  }
  const { setColor, setColor1,setShowLoading } = useContext(HomeContext);
    useEffect(()=>{
    setColor1('#828282');
    setColor('#002087')
  })

  const handleSubmit = (e) =>{
    e.preventDefault();
    console.log(enableBtn,'submit')
    if(!enableBtn){
      const url = `account/register`;
      const body = {
        username:userName,
        email,
        password,
        userRole: 'root'
      }
      console.log(Api.check_network(),'network')
      if(!Api.check_network() || enableBtn) return false;
      setShowLoading(true);
      Api.post({url,body},false)
      .then(data =>{
        console.log(data,'data');
        if(data && data.isSuccessful){
           setUserEmail(email);
           setId(data.id);
           setShowLoading(false);
           navigate('/verify-register');
        }
        else{
          setShowLoading(false);
          alert(`${data.message}`);
        }

      })
      .catch(err =>{
        setShowLoading(false);
        console.log(err,'error');

      })
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Logo/>
      <div className={css.formheader}>
        <span className={css.head1}>Create account</span>
        <span className={css.head2}>Already have an account? <Link to='/login'>Login</Link></span>
      </div>
      <div className={css.mainform}>
        <div className={css.form1}>
          <input type="text" onChange={(e)=>setUserName(e.target.value)} placeholder='Username' />
          <input type="email" onChange={(e)=>setEmail(e.target.value)} placeholder='Email' />
        </div>
        <div className={css.form2}>
          <input type={changeType} onChange={(e)=>setPassword(e.target.value)} placeholder='Password' />
          {showEye === true?<OpenEye className={css.img3} onClick={()=> changeInpType()} />:<CloseEye className={css.img3} onClick={()=> changeInpType()} />}
        </div>
        <div className={css.form2}>
          <input type={changeType1} onChange={(e)=>setConfirmPassword(e.target.value)} placeholder='Confirm Password' />
          {showEye1 === true?<OpenEye className={css.img3} onClick={()=> changeInpType1()} />:<CloseEye className={css.img3} onClick={()=> changeInpType1()} />}
        </div>  
        <span className={css.result1}>{password.length === 0 || confirmPassword.length === 0?'': result} {email.length === 0?'':result1}</span>                                                                                      
        <span className={css.form3}><input type="checkbox" onChange={(e)=>setAddCheck(e.target.checked)} value={addCheck} />I agree to C-Manager <a href='https://c-manager.xyz/terms-of-use'> Terms of service</a> and <a href="https://c-manager.xyz/privacy-policy">Privacy policy</a></span>
        <div className={css.form4}>
            <button disabled={enableBtn} style={!enableBtn?{cursor:'pointer'}:{cursor:'not-allowed'}}>Create Account</button>
          <span className={css.or}>or</span>
          <Link><Google/>Continue with google</Link>
        </div>
      </div>
    </form>
  )
}

export default MainSignUp