import AccountSideBar from "../component/AccountSideBar"
import css from './css/home.module.css'
import SignUp from "./pages/MainSignUP"
import VerifySignUp from "./pages/Signup"
import CheckEmail from "./pages/CheckEmail"
import Login from "./pages/MainLogin"
import ForgotPassword from "./pages/ForgotPassword"
import ResetPassword from "./pages/ResetPassword"
import PrimaryLogin from "./pages/PrimaryLogin"
import SecondaryLogin from "./pages/SecondaryLogin"
import SuccessfullReset from "./pages/SuccessfullReset";
import PageNotFound from './not-found';
import { useState, createContext } from "react";
import {Routes, Route} from "react-router-dom";
import Loading from "../component/loading";
import Modal from "../component/modal";
export const HomeContext = createContext();

const Home = () => {
    const [color1, setColor1] = useState('');
    const [color, setColor] = useState('');
    const [showLoading, setShowLoading] = useState(false);
    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState("");

  return (
    <div className={css.maindiv1}>
        <Loading shouldShow={showLoading} />
        <Modal modalObject={{ header: '', footer: '', body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
        <AccountSideBar color1={color1} color={color} />
        <div className={css.currentPage1}>
            <HomeContext.Provider value={{ setColor, setColor1, setShowLoading, setModalText, setModalToggle}}>
                <Routes>
                    <Route index element={<SignUp />} />
                    <Route path= "/register/*" element={<SignUp />} />
                    <Route path= "/verify-register/*" element={<VerifySignUp />} />
                    <Route path= "/login/*" element={<Login />} />
                    <Route path= "/verify-email/:encrypt" element={<CheckEmail />} />
                    <Route path= "/forgot-password/*" element={<ForgotPassword />} />
                    <Route path= "/change-password/:email" element={<ResetPassword />} />
                    <Route path= "/primary-login/*" element={<PrimaryLogin />} />
                    <Route path= "/secondary-login/*" element={<SecondaryLogin />} />
                    <Route path= "/successful/*" element={<SuccessfullReset />} />
                    <Route path="/*" element={<PageNotFound />} />
                </Routes>
            </HomeContext.Provider>
        </div>
    </div>
  )
}

export default Home