import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import Home from "./views/home";
import { createContext, useState } from "react";


export const AppContext = createContext({});

function App() {
  const [userEmail,setUserEmail] = useState('');
  const [id,setId] = useState(null);
  const value={
    userEmail,setUserEmail,
    id,setId
  }

  return (
    <div className="App">
      <AppContext.Provider value={value}>
        <Router>
          <Routes>
              <Route path= "/*" element={<Home />} />
          </Routes>
        </Router>
      </AppContext.Provider>
    </div>
  );
}

export default App;
