import css from '../css/mainlogin.module.css'
import { Link } from 'react-router-dom'
import { useEffect, useContext } from 'react';
import { HomeContext } from "../home";
import Logo from '../../component/logo'

const MainLogin = () => {
  const { setColor, setColor1 } = useContext(HomeContext);
    useEffect(()=>{
    setColor('#828282');
    setColor1('#002087')
  })
  return (
    <div className={css.reLogin}>
      <Logo/>
      <div className={css.relink1}>
        <span className={css.re1}>Login As</span>
        <span>You can login as a primary user (route user) or secondary user.</span>
      </div>
      <div className={css.relink}>
        <Link to='/primary-login'>
          <span className={css.re2}>Primary User</span>
          <span>Welcome back! Please enter your account credentials to access the your solutions. Available to users that directly registered to C-Manager</span>
        </Link>
        <Link to='/secondary-login'>
          <span className={css.re2}>Secondary User</span>
          <span>You can access certain tools and features in C-Manager. Log in now to start managing your tasks. Available to staff members</span>
        </Link>
      </div>
    </div>  
  )
}

export default MainLogin