import css from '../css/resets.module.css'
import { Link } from 'react-router-dom'
import Pic1 from '../images/checked.png'
import { useEffect, useContext } from 'react';
import { HomeContext } from "../home";
import Logo from '../../component/logo'

const SuccessfullReset = () => {
  const { setColor, setColor1 } = useContext(HomeContext);
    useEffect(()=>{
    setColor('#828282');
    setColor1('#002087')
  })
  return (
    <div className={css.reset}>
      <Logo/>
      <img src={Pic1} alt="" />
      <span>Password reset successfully</span>
      <Link to="/login">Login</Link>
    </div>
  )
}

export default SuccessfullReset