import style from "../css/loading.module.css";

const Loading = ({shouldShow}) =>{
    return(
        <>
            <div className={style.parent} style={{display: shouldShow?"flex":'none'}}>
                <div className={style.loader}></div>
            </div>
        </>
    )
}

export default Loading;