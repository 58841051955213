import css from '../css/forgotpassword.module.css'
import { Link } from 'react-router-dom'
import { useEffect, useContext, useState } from 'react';
import { HomeContext } from "../home";
import Logo from '../../component/logo';
import Api from '../../api';

var valid_email =  /^(([^<>()[\],;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ForgotPassword = () => {
    const { setColor, setColor1 } = useContext(HomeContext);
    const [email,set_email] = useState('');
    const [disabled,set_disabled] = useState(true);
    const {setShowLoading} = useContext(HomeContext)
    const handleEmail = (e)=>{
        const {target:{value}} = e;
        set_email(value);
        if(value.match(valid_email)){
            set_disabled(false);
        }
    }
    const handleForgotPassword = ()=>{
        if(!disabled){
            if(!Api.check_network())return false;
            setShowLoading(true);
            const url = `account/login/reset-password`;
            const body = {
                email,
            }
            Api.post({url,body},false)
            .then(data =>{
                console.log(data,'data')
                if(data.isSuccessful){
                    alert(data.message);                
                }
                else{
                    alert(data.message);
                }
                setShowLoading(false);
            })
            .catch(err =>{
                setShowLoading(false);
                console.log(err,'error');
                alert(err.message);

            })

        }
    }

    useEffect(()=>{
        setColor('#828282');
        setColor1('#002087')
    },[])
  return (
    <div className={css.forpass}>
        <Logo/>
        <div className={css.for1}>
            <span className={css.pass1}>Forgot Password</span>
            <span>Enter the email you used to create your account so we can send you instructions on how to reset your password.</span>
        </div>
        <div className={css.for2}>
            <input type="email" onChange={(e)=>handleEmail(e)} placeholder='Email' value={email} name="" id="" />
            <button onClick={handleForgotPassword} disabled={disabled}>
                Send
            </button>
            <Link to='/login'>Back to Login</Link>
        </div>
    </div>
  )
}

export default ForgotPassword