import css from '../views/css/logo.module.css'
import pic from '../views/images/logo.png'

const logo = () => {
  return (
    <div className={css.top}>
        <img src={pic} alt="" />
        <span className={css.subcontent}>Manager</span>
    </div>
  )
}

export default logo