import css from '../css/email.module.css'
import { Link, useParams } from 'react-router-dom'
import { BsEyeFill as OpenEye } from 'react-icons/bs' 
import { BsEyeSlashFill as CloseEye } from 'react-icons/bs'
import { useState, useContext, useEffect } from 'react'
import { HomeContext } from "../home";
import Logo from '../../component/logo'
import Api from '../../api'

const ResetPassword = () => {
  const [changeType, setChangeType] = useState("password")
  const [changeType1, setChangeType1] = useState("password")
  const [showEye,setShowEye] =useState(true);
  const [password,set_password] = useState('');
  const [confirm_password,set_confirm_password] = useState('');
  const [disabled,set_disabled] = useState(true);
  const {email} = useParams();
  const {setShowLoading} = useContext(HomeContext);

  const changeInpType = ()=>{
      if(changeType === "password"){
        setChangeType('text')
        setChangeType1('text')
        setShowEye(false)
      }
      else{
        setChangeType("password")
        setChangeType1("password")
        setShowEye(true)
      }
  }
  const handleChangePassword = () =>{
      if(!disabled){
        if(!Api.check_network()) return;
        console.log(email,'email');
        setShowLoading(true);
        const url = `account/login/change-password`;
        const body = {email,password};
        Api.post({url,body},false)
        .then(data =>{
          console.log(data,'data')
          alert(data.message)
          setShowLoading(false);
        })
        .catch(err=>{
          console.log(err.message);
          setShowLoading(false);
          alert(err.message);
        })
      }
  }
  const { setColor, setColor1 } = useContext(HomeContext);
  useEffect(()=>{
    setColor('#828282');
    setColor1('#002087')
  },[])

  useEffect(()=>{
    if(password && confirm_password){
       if(password == confirm_password){
        set_disabled(false);
       }
       else{
        set_disabled(true);
       }
    }
  },[password,confirm_password])

 

  return (
    <div className={css.veri}>
      <Logo/>
      <div className={css.veri1}>
        <span className={css.verisp1}>Change Password</span>
        <span className={css.verisp2}>Choose a new Password for your account</span>
      </div>
      <div className={css.veri1}>
        <div className={css.veriem}>
          <div>
              <input type={changeType1} value={password} onChange={(e)=>set_password(e.target.value)} placeholder='Your new password' name="" id="" />
              {showEye === true?<OpenEye className={css.img3} onClick={()=> changeInpType()} />:<CloseEye className={css.img3} onClick={()=> changeInpType()} />}
          </div>
          <div>
              <input value={confirm_password} onChange={(e)=>set_confirm_password(e.target.value)} type={changeType} placeholder='Confirm your new password' name="" id="" />
              {showEye === true?<OpenEye className={css.img3} onClick={()=> changeInpType()} />:<CloseEye className={css.img3} onClick={()=> changeInpType()} />}
          </div>
        </div>
        <button onClick={handleChangePassword} disabled={disabled}> Reset Password</button>
        <Link className={css.veria} to='/login'>Back to Login</Link>
      </div>          
    </div>
  )
}

export default ResetPassword