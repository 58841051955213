import css from '../css/login.module.css'
import { Link } from 'react-router-dom'
import { FcGoogle as Google } from 'react-icons/fc' 
import { BsEyeFill as OpenEye } from 'react-icons/bs' 
import { BsEyeSlashFill as CloseEye } from 'react-icons/bs'
import { useState, useContext, useEffect, useRef } from 'react'
import { HomeContext } from "../home";
import Logo from '../../component/logo';
import Api from '../../helperFunctions/Api';
import { useCookies } from 'react-cookie'

const PrimaryLogin = () => {
    const [changeType, setChangeType] = useState("password")
    const [showEye,setShowEye] =useState(true);
    const [email,setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [enableBtn ,setEnableBtn] = useState(true)
    const {setShowLoading,setModalText, setModalToggle} = useContext(HomeContext)
    const [cookies, setCookie] = useCookies(['token', 'user']);

    const changeInpType = ()=>{
        if(changeType === "password"){
          setChangeType('text')
          setShowEye(false)
        }
        else{
          setChangeType("password")
          setShowEye(true)
        }
    }
    const { setColor, setColor1 } = useContext(HomeContext);
        useEffect(()=>{
        setColor('#828282');
        setColor1('#002087')
    })
    
    useEffect(()=>{
        if(email.length > 0 && password.length > 0){
          setEnableBtn(false)
        }
        else{
          setEnableBtn(true)
        }
      },[email, password])

    const HandleSubmit = (e)=>{
        e.preventDefault();

        //Do validation here

        const obj = {
            email: email,
            password: password
        }

        setShowLoading(true);

        // console.log(obj, "obj")

        Api.PostApi('/account/login',obj)
        .then(result=>{
            // console.log(result,"response")
            setShowLoading(false);
            setModalText(result.message);
            setModalToggle(true);
            if(result.isSuccessful){
                // localStorage.setItem('cmanager_tkn1234', result.token);

                // const token = result.token;
                // const user = result.user;
                // setCookie('token', token)
                // document.cookie = `token=${JSON.stringify(token)}`;
                // setCookie('user', user)
                // document.cookie = `user=${JSON.stringify(user)}`;

                // localStorage.setItem('cmag-user',JSON.stringify(result.user));
                // localStorage.setItem('cmag-token',JSON.stringify(result.token));   
                let data = result.data;        
                window.location.href = process.env.NODE_ENV == 'production'?`https://hr.c-manager.xyz/account/user/${data}`:`http://localhost:3001/account/user/${data}`;
            }
        })
    }

  return (
    <form className={css.form} >
        <Logo/>
        <div className={css.form1}>
            <span className={css.head1}>Welcome Back</span>
            <span className={css.head2}>Don't have an account? <Link to='/register'>sign up</Link></span>
        </div>
        <div className={css.form2}>
            <div>
                <input onChange={(e)=>setEmail(e.target.value)} className={css.forminp} type="email" placeholder='User email' />
                <div className={css.reform}>
                    <div>
                        <input onChange={(e)=>setPassword(e.target.value)} className={css.forminp} type={changeType} placeholder='Password' />
                        {showEye === true?<OpenEye className={css.img3} onClick={()=> changeInpType()} />:<CloseEye className={css.img3} onClick={()=> changeInpType()} />}
                    </div>
                    <Link to='/forgot-password'>Forgot Password?</Link>
                </div>
            </div>
            <div className={css.form3}>
                <button disabled={enableBtn} style={!enableBtn?{cursor:'pointer'}:{cursor:'not-allowed'}} onClick={e=>HandleSubmit(e)}>Login</button>
                <span className={css.or}>or</span>
                <Link><Google/>Continue with google</Link>
            </div>
        </div>
    </form>
  )
}

export default PrimaryLogin